body {
  padding-left: 100px;
}

th,
td {
  line-height: 10px;
  height: 10px;
  min-height: 10px;
  max-height: 10px;

  width: 10px;
  min-width: 10px;
  max-width: 10px;
}

table,
th,
td {
  border: 2px black solid;
  border-collapse: collapse;
}

table {
  font-size: 64pt;
  font-weight: 800;
  text-align: center;
  table-layout: fixed;
  min-width: 1000px;
  min-height: 1000px;
}

.resource-tray button {
  padding: 0 0;
  height: 100px;
  width: 100px;
  color: white;
  font-size: 64pt;
  font-weight: 800;
  line-height: 64pt;
}
